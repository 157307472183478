import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.api_base_url;

export default class ORBApi {
  static getRequestAbortSource() {
    return axios.CancelToken.source();
  }

  static abortRequest(cancelSource) {
    cancelSource.cancel('Operation cancelled by the user');
  }

  static isCancel(error) {
    return axios.isCancel(error);
  }

  static _doGetReturningResponse(url, responseType, abortSource) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    if (abortSource) requestOptions.cancelToken = abortSource.token;
    return axios
      .get(url, requestOptions)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doGet(url, responseType, abortSource) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    if (abortSource) requestOptions.cancelToken = abortSource.token;
    return axios
      .get(url, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doPost(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .post(url, data, {
        headers: {
          'content-type': contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doPut(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .put(url, data, {
        headers: {
          'content-type': contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doPatch(url, data, contentType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    if (!contentType) contentType = 'application/json';
    return axios
      .patch(url, data, {
        headers: {
          'content-type': contentType,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  static _doDelete(url, responseType) {
    axios.defaults.headers.common['x-orb-auth'] =
      localStorage.getItem('auth_token');
    let requestOptions = {};
    if (responseType) requestOptions.responseType = responseType;
    return axios
      .delete(url, requestOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.replace('/');
        }
        throw error;
      });
  }

  //SESSION MANAGEMENT -------------------------------------------------------------------------------------------------------------

  static async doRecoverPassword(newpass, otpToken) {
    // here we have to access directly axios, to send a different token
    return axios.post(
      '/public/users/me/password',
      { new_password: newpass },
      { headers: { 'x-orb-auth': otpToken } },
    );
  }

  static async doLogout() {
    return await ORBApi._doDelete('private/session');
  }

  static async doLogin(username, password) {
    return await ORBApi._doPost('public/sessions', {
      username: username,
      password: password,
    });
  }

  static async getSession() {
    return await ORBApi._doGet('private/session');
  }

  static async sendPasswordReminder(email) {
    return await ORBApi._doPost('/public/users/password_reminder', {
      email: email,
    });
  }

  static async getLoggedUser() {
    return await ORBApi._doGet('/private/users/me');
  }

  static async doChangePassword(currentpass, newpass) {
    return await ORBApi._doPost('private/users/me/password', {
      current_password: currentpass,
      new_password: newpass,
    });
  }
  //DISTRIBUTION -------------------------------------------------------------------------------------------------------------------
  static async loadDistributions(page, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters.name) params.push(`name=${filters.name}`);
    if (filters.reference_date)
      params.push(`reference_date=${filters.reference_date}`);
    if (filters.status) params.push(`status=${filters.status}`);

    if (params.length > 0) url_params = `?${params.join('&')}`;

    return await ORBApi._doGet(`private/distributions${url_params}`);
  }

  static async loadSingleDistribution({ distribution_id }) {
    return await ORBApi._doGet(`private/distributions/${distribution_id}`);
  }

  static async downloadDistribution(distribution_id, to_email = 0) {
    return await ORBApi._doGetReturningResponse(
      `/private/distributions/${distribution_id}/summary?to_email=${to_email}
    `,
      'arraybuffer',
    );
  }
  static async loadSingleWorkDistribution({ distribution_id }) {
    return await ORBApi._doGet(`private/work/distributions/${distribution_id}`);
  }

  static async loadReportsDistribution(filters, page_size = 10) {
    let params = [];
    let url_params = '';
    if (filters?.client_id) params.push(`client=${filters.client_id}`);
    if (filters?.month) params.push(`month=${filters.month}`);
    if (filters?.year) params.push(`year=${filters.year}`);
    if (filters?.contributor_id)
      params.push(`contributor_id=${filters.contributor_id}`);
    if (filters?.page) params.push(`page=${filters.page}`);
    params.push(`page_size=${page_size}`);

    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(`/private/distributions/reports${url_params}`);
  }
  static async loadContributorsDistribution(page = 1, filters, page_size = 10) {
    let params = [];
    let url_params = '';

    if (filters?.client_id) params.push(`client=${filters.client_id}`);
    if(filters?.distribution_id) params.push(`distribution_id=${filters.distribution_id}`)

    if (filters?.contributor_id)
      params.push(`contributor=${filters.contributor_id}`);
    if (filters?.month)
      params.push(`month=${filters.month}`);
    if (filters?.year)
      params.push(`year=${filters.year}`);
    if (page) params.push(`page=${page}`);
    params.push(`page_size=${page_size}`);

    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(
      `/private/distributions/contributors${url_params}`,
    );
  }
  static async loadIndependentContributorsDistribution(
    page = 1,
    filters,
    page_size = 10,
  ) {
    let params = [];
    let url_params = '';

    if (filters?.contributor_id)
      params.push(`contributor=${filters.contributor_id}`);
    if (filters?.distribution_id)
      params.push(`distribution_id=${filters.distribution_id}`);
    if (filters?.month)
      params.push(`month=${filters.month}`);
    if (filters?.year)
      params.push(`year=${filters.year}`);
    if (page) params.push(`page=${page}`);
    params.push(`page_size=${page_size}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/distributions/contributors/independent${url_params}`,
    );
  }

  static async loadContributorsFromLabelDistribution(queries) {
    return await ORBApi._doGet(
      `/private/distributions/${queries.distribution_id}/contributors?label=${queries.label_id}&contributor_name=${queries.contributor_filter}&page=${queries.contributor_page}`,
    );
  }

  static async downloadClientDistribution(distribution_id, client_id) {
    let url_params = '';
    let params = [];

    if (client_id !== undefined) params.push(`client_id=${client_id}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGetReturningResponse(
      `/private/distributions/${distribution_id}/reports/excel${url_params}`,
      'blob',
    );
  }

  static async sendEmailDistribution(report) {
    let uri = `/private/distributions/${report.distribution_id}/reports/email`;
    if (report.client_id !== undefined) {
      uri += `?client_id=${report.client_id}`;
    }
    return await ORBApi._doGet(uri);
  }

  static async postDistribution(name, reference_date, currency) {
    return await ORBApi._doPost(`/private/distributions`, {
      name: name,
      reference_date: reference_date,
      currency,
    });
  }

  static async approveDistribution(distribution_id) {
    return await ORBApi._doPost(
      `/private/distributions/${distribution_id}/approval`,
      {},
    );
  }

  static async reproveDistribution(distribution_id) {
    return await ORBApi._doDelete(
      `/private/distributions/${distribution_id}/approval`,
    );
  }

  static async updateDistribution(
    distribution_id,
    name,
    reference_date,
    currency,
  ) {
    return await ORBApi._doPatch(`/private/distributions/${distribution_id}`, {
      name: name,
      reference_date: reference_date,
      currency: currency,
    });
  }

  //LABELS -------------------------------------------------------------------------------------------------------------------
  static async loadLabels(page, filters, page_size = 20) {
    let url_params = '';
    let params = [];
    params.push(`page=${page}`);
    if (filters?.label_name) params.push(`label_name=${filters.label_name}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/labels${url_params}&page_size=${page_size}`,
    );
  }

  static async loadLabelDetails(id) {
    return await ORBApi._doGet(`/private/labels/${id}`);
  }

  static async patchLabel(body) {
    return await ORBApi._doPatch(`private/labels/${body.id}`, body);
  }

  static async postLabel(body) {
    return await ORBApi._doPost(`/private/labels`, body);
  }

  //TRACKS -------------------------------------------------------------------------------------------------------------------
  static async loadTracks(page, label, search_term, contributor_id) {
    if (!page) page = 1;
    let url = `/private/tracks?page=${page}`;
    if (label) url += `&label=${label}`;
    if (search_term) url += `&search_term=${search_term}`;
    if (contributor_id) url += `&contributor_id=${contributor_id}`;
    return await ORBApi._doGet(url);
  }

  static async loadTrackDetails(track_id) {
    return await ORBApi._doGet(`/private/tracks/${track_id}`);
  }

  static async downloadTracks(filters) {
    const { label_id, search_term, contributor_id } = filters;
    let params = [];
    let url_params = '';
    if (label_id) params.push(`label=${label_id}`);
    if (search_term) params.push(`search_term=${search_term}`);
    if (contributor_id) params.push(`contributor_id=${contributor_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(`private/tracks/excel${url_params}`, 'blob');
  }

  static async postTracks(body, type) {
    return await ORBApi._doPost('/private/tracks', body, type);
  }

  static async deleteTrack(track_id) {
    return await ORBApi._doDelete(`/private/tracks/${track_id}`);
  }

  //PAYMENT REPORTS ----------------------------------------------------------------------------------------------------------------
  static async loadFilesToDistribute(page = 1, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(
      `/private/distributions/payment/reports${url_params}`,
    );
  }
  static async loadPaymentReports(page = 1, filters) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }
    if (filters.date) {
      params.push(`month=${filters.date}`);
    }
    if (filters.source) {
      params.push(`source=${filters.source}`);
    }

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`/private/payment_reports${url_params}`);
  }
  static async loadPaymentWorksReports(page = 1, filters) {
    let url_params = '';
    let params = [];
    params.push(`page=${page}`);
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }
    if (filters.date) {
      params.push(`month=${filters.date}`);
    }
    if (filters.source) {
      params.push(`source=${filters.source}`);
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`/private/work/payment/reports${url_params}`);
  }

  static async loadPaymentReportCurrencies(report_id) {
    return await ORBApi._doGet(
      `/private/payment_reports/${report_id}/currencies`,
    );
  }
  static async loadPaymentWorkReportCurrencies(report_id) {
    return await ORBApi._doGet(
      `private/work/payment/reports/${report_id}/currencies`,
    );
  }

  static async postPaymentReports(formData) {
    return await ORBApi._doPost(
      `/private/payment_reports`,
      formData,
      'multipart/form-data',
    );
  }

  static async updatePaymentDetails(report_id, body) {
    return await ORBApi._doPatch(`private/payment_reports/${report_id}`, body);
  }

  static async updatePaymentWorkReportCurrency(report_id, currency, rate) {
    return await ORBApi._doPatch(
      `/private/work/payment/reports/${report_id}/currencies/${currency}`,
      { exchange_rate: rate },
    );
  }

  static async updatePaymentReportCurrency(report_id, currency, rate) {
    return await ORBApi._doPatch(
      `/private/payment_reports/${report_id}/currencies/${currency}`,
      { exchange_rate: rate },
    );
  }

  static async deletePaymentReports(report_id) {
    return await ORBApi._doDelete(`/private/payment_reports/${report_id}`);
  }

  static async deletePaymentWorkReports(report_id) {
    return await ORBApi._doDelete(`/private/work/payment/reports/${report_id}`);
  }

  static async loadPaymentReportNotFoundTracks(report_id, page) {
    return await ORBApi._doGet(
      `/private/payment_reports/${report_id}/not_found_tracks?page=${page}&page_size=5`,
    );
  }

  static async downloadPaymentReportNotFoundTracks(report_id) {
    return await ORBApi._doGet(
      `/private/payment_reports/${report_id}/not_found_tracks/excel`,
      'blob',
    );
  }

  static async rebuildPaymentReportNotFoundTracks(report_id) {
    return await ORBApi._doPost(
      `/private/payment_reports/${report_id}/not_found_tracks`,
    );
  }

  static async loadPaymentReportParcialMatchesTracks(report_id, page) {
    return await ORBApi._doGet(
      `/private/payment_reports/${report_id}/parcial_matches?page=${page}&page_size=5`,
    );
  }
  static async downloadPaymentParcialMatchesTracks(report_id) {
    return await ORBApi._doGet(
      `/private/payment_reports/${report_id}/parcial_matches/excel`,
      'blob',
    );
  }

  static async rebuildPaymentReportParcialMatchesTracks(report_id) {
    return await ORBApi._doPost(
      `/private/payment_reports/${report_id}/parcial_matches`,
    );
  }

  //REPORTING ----------------------------------------------------------------------------------------------------------------

  static async downloadReportExcel(report, download_type) {
    let { distribution_id, client_id, contributor_id } = report;

    let url_params = '';
    const params = [];

    if (client_id !== null) params.push(`client_id=${client_id}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGetReturningResponse(
      `/private/distributions/${distribution_id}/contributors/${contributor_id}/${download_type}${url_params}`,
      'blob',
    );
  }
  static async downloadReportPdf(report, download_type, to_email = 0) {
    let { distribution_id, client_id, contributor_id } = report;

    let url_params = '';
    const params = [];

    if (client_id !== null) params.push(`client_id=${client_id}`);
    if (contributor_id !== null)
      params.push(`contributor_id=${contributor_id}`);
    if (download_type === 'pdf') params.push(`to_email=${to_email}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGetReturningResponse(
      `/private/distributions/${distribution_id}/contributor/${download_type}${url_params}`,
      'blob',
    );
  }

  static async downloadWorkReport(report, download_type, to_email = 0) {
    let { distribution_id, client_id, contributor_id } = report;
    let url_params = '';
    const params = [];

    if (client_id) params.push(`publisher_id=${client_id}`);
    if (contributor_id) params.push(`contributor_id=${contributor_id}`);
    if (download_type === 'pdf') params.push(`to_email=${to_email}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(
      `/private/work/distributions/${distribution_id}/reports/${download_type}${url_params}`,
      'blob',
    );
  }
  //CLIENTS ----------------------------------------------------------------------------------------------------------------

  static async loadClientsDropdown() {
    return await ORBApi._doGet(`/private/clients?page_size=0`);
  }

  static async loadClients(page, filters, page_size = 10) {
    let url_params = '';
    let params = [];

    if (page) params.push(`page=${page}`);
    if (filters.client) params.push(`client=${filters.client}`);
    if (filters.label_id) params.push(`label_id=${filters.label_id}`);
    if (filters.publisher_id)
      params.push(`publisher_id=${filters.publisher_id}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/clients${url_params}&page_size=${page_size}`,
    );
  }

  static async postClients(body) {
    return await ORBApi._doPost(`private/clients`, body);
  }

  static async putClients(body) {
    return await ORBApi._doPut(`private/clients/${body.id}`, body);
  }

  //PUBLISHERS ----------------------------------------------------------------------------------------------------------------
  static async loadPublishers(page, filters, page_size = 20) {
    let url_params = '';
    let params = [];
    params.push(`page=${page}`);

    if (filters?.publisher_name)
      params.push(`publisher_name=${filters.publisher_name}`);

    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(
      `private/publishers${url_params}&page_size=${page_size}`,
    );
  }

  static async loadPublisherDetails(queries) {
    return await ORBApi._doGet(`private/publishers/${queries.publisher_id}`);
  }

  static async putPublisher(body) {
    return await ORBApi._doPut(`private/publishers/${body.id}`, body);
  }

  static async postPublisher(body) {
    return await ORBApi._doPost(`private/publishers`, body);
  }

  //WORKS ----------------------------------------------------------------------------------------------------------------------

  static async loadWorks(page = 1, filters) {
    let url_params = '';
    let params = [];
    params.push(`page=${page}`);
    if (filters.publisher_id) {
      params.push(`publisher_id=${filters.publisher_id}`);
    }
    if (filters.search_term) {
      params.push(`search_term=${filters.search_term.toUpperCase()}`);
    }
    if (filters.contributor_id) {
      params.push(`contributor_id=${filters.contributor_id}`);
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }
    return await ORBApi._doGet(`private/works${url_params}`);
  }

  static async loadWorkDetails(queries) {
    return await ORBApi._doGet(`private/works/${queries.backoffice_id}`);
  }

  static async postWorks(body) {
    return await ORBApi._doPost(`private/works`, body);
  }

  static async putWorks(body) {
    return await ORBApi._doPut(`private/works/${body.id}`, body);
  }

  static async deleteWork(work_id) {
    return await ORBApi._doDelete(`/private/works/${work_id}`);
  }

  static async downloadWorks(filters) {
    const { publisher_id, search_term, contributor_id } = filters;
    let params = [];
    let url_params = '';
    if (publisher_id) params.push(`publisher_id=${publisher_id}`);
    if (search_term) params.push(`search_term=${search_term}`);
    if (contributor_id) params.push(`contributor_id=${contributor_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(`private/works/excel${url_params}`, 'blob');
  }

  static async postWorksFile(body, type) {
    return await ORBApi._doPost(`/private/works`, body, type);
  }

  //WORK PAYMENT REPORTS-------------------------------------------------------------------------------------------------------
  static async getWorkPaymentReports(queries) {
    return await ORBApi._doGet(
      `private/work/payment/reports?page=${queries.page}`,
    );
  }

  static async loadWorkPaymentDetails(queries) {
    return await ORBApi._doGet(
      `private/work/payment/reports/${queries.report_id}`,
    );
  }

  static async updateWorkPaymentDetails(report_id, body) {
    return await ORBApi._doPatch(
      `private/work/payment/reports/${report_id}`,
      body,
    );
  }

  static async postWorkPaymentReports(formData) {
    return await ORBApi._doPost(
      `private/work/payment/reports`,
      formData,
      'multipart/form-data',
    );
  }

  static async loadWorkPaymentReportNotFoundWorks(report_id, page = 1) {
    return await ORBApi._doGet(
      `/private/work/payment/reports/${report_id}/not_found_works?page=${page}`,
    );
  }

  static async downloadWorkPaymentReportNotFoundWorks(report_id) {
    return await ORBApi._doGet(
      `/private/work/payment/reports/${report_id}/not_found_works/excel`,
      'blob',
    );
  }

  static async rebuildWorkPaymentReportNotFoundWorks(report_id) {
    return await ORBApi._doPost(
      `/private/work/payment/reports/${report_id}/not_found_works`,
    );
  }

  //UPLOAD LABEL LOGO----------------------------------------------------------------------------------------------------------
  static async postLabelLogo(formData) {
    return await ORBApi._doPost(`private/logo`, formData);
  }

  static async patchLabelLogo(formData, label_id) {
    return await ORBApi._doPatch(`private/logo/${label_id}`, formData);
  }

  //Reporting
  static async getDistribution({ distribution_id, client_id, contributor_id }) {
    let url_params = '';
    let params = [];

    if (client_id !== null) params.push(`client_id=${client_id}`);
    if (contributor_id !== null)
      params.push(`contributor_id=${contributor_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/distributions/${distribution_id}/contributor/executions${url_params}`,
    );
  }

  static async getWorkExecutions(
    distribution_id,
    client_id,
    contributor_id,
    page,
  ) {
    let url_params = '';
    let params = [];

    if (client_id !== null) params.push(`client_id=${client_id}`);
    if (contributor_id !== null)
      params.push(`contributor_id=${contributor_id}`);
    if (page) params.push(`page=${page}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/work/distributions/${distribution_id}/executions${url_params}&page_size=10`,
    );
  }

  static async getTrackExecutions(
    distribution_id,
    client_id,
    contributor_id,
    page,
  ) {
    let url_params = '';
    let params = [];

    if (client_id !== null) params.push(`client_id=${client_id}`);
    if (contributor_id !== null)
      params.push(`contributor_id=${contributor_id}`);
    if (page) params.push(`page=${page}`);

    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `/private/distributions/${distribution_id}/contributor/tracks/executions${url_params}&page_size=10`,
    );
  }

  // chamada de graficos de CONEXO

  static async loadTrackDspRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    label_id,
    abortSource,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (label_id) params.push(`label=${label_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/tracks/dashboard/dsp${url_params}`,
      null,
      abortSource,
    );
  }
  static async loadTrackRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    label_id,
    abortSource,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (label_id) params.push(`label=${label_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/tracks/dashboard${url_params}`,
      abortSource,
    );
  }
  static async loadTrackContributorRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    label_id,
    abortSource,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (label_id) params.push(`label=${label_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');
    return await ORBApi._doGet(
      `private/tracks/dashboard/contributor${url_params}`,
      abortSource,
    );
  }
  static async loadTrackMonthlyRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    label_id,
    abortSource,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (label_id) params.push(`label=${label_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/tracks/dashboard/month${url_params}`,
      null,
      abortSource,
    );
  }
  static async loadTrackTerritoryRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    label_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (label_id) params.push(`label=${label_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/tracks/dashboard/territory${url_params}`,
    );
  }

  // chamada de graficos de AUTORAL
  static async loadWorkMonthlyRevenue(
    startDate,
    endDate,
    dateType,
    workId,
    contributorId,
    publisher_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (workId) params.push(`work_id=${workId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (publisher_id) params.push(`publisher=${publisher_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(`private/works/executions/month${url_params}`);
  }
  static async loadWorksDspRevenue(
    startDate,
    endDate,
    dateType,
    trackId,
    contributorId,
    publisher_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (trackId) params.push(`track_id=${trackId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (publisher_id) params.push(`publisher=${publisher_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(`private/works/executions/dsp${url_params}`);
  }
  static async loadWorksRevenue(
    startDate,
    endDate,
    dateType,
    workId,
    contributorId,
    publisher_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (workId) params.push(`work_id=${workId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (publisher_id) params.push(`publisher=${publisher_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(`private/works/executions${url_params}`);
  }
  static async loadWorksTerritoryRevenue(
    startDate,
    endDate,
    dateType,
    workId,
    contributorId,
    publisher_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (workId) params.push(`work_id=${workId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (publisher_id) params.push(`publisher=${publisher_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/works/executions/territory${url_params}`,
    );
  }
  static async loadWorksContributorsRevenue(
    startDate,
    endDate,
    dateType,
    workId,
    contributorId,
    publisher_id,
  ) {
    let url_params = '';
    let params = [];
    if (startDate) params.push(`start_date=${startDate}`);
    if (endDate) params.push(`end_date=${endDate}`);
    if (dateType) params.push(`date_type=${dateType}`);
    if (workId) params.push(`work_id=${workId}`);
    if (contributorId) params.push(`contributor_id=${contributorId}`);
    if (publisher_id) params.push(`publisher=${publisher_id}`);
    if (params.length > 0) url_params = '?' + params.join('&');

    return await ORBApi._doGet(
      `private/works/executions/contributor${url_params}`,
    );
  }

  // CONTRIBUTORS

  static async loadContributorsName(page_size = 0) {
    let url_params = '';
    let params = [];

    params.push(`page_size=${page_size}`);

    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(`private/contributors${url_params}`);
  }

  static async loadSingleContributor(id) {
    return await ORBApi._doGet(`private/contributors/${id}`);
  }

  static async loadContributors(page = 1, filters, page_size = 10) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters) {
      if (filters.term) {
        params.push(`search_term=${filters.term.toUpperCase()}`);
      }
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(
      `private/contributors${url_params}&page_size=${page_size}`,
    );
  }
  static async postContributors(body) {
    return await ORBApi._doPost(`private/contributors`, body);
  }
  static async putContributors(body) {
    return await ORBApi._doPut(`private/contributors/${body.id}`, body);
  }

  // AGENTS

  static async loadAgents(page = 1, filters, page_size = 10) {
    let url_params = '';
    let params = [];

    params.push(`page=${page}`);
    if (filters) {
      if (filters.agent) {
        params.push(`agent=${filters.agent}`);
      }
    }
    if (params.length > 0) {
      url_params = '?' + params.join('&');
    }

    return await ORBApi._doGet(
      `private/agents${url_params}&page_size=${page_size}`,
    );
  }

  static async postAgents(body) {
    return await ORBApi._doPost(`private/agents`, body);
  }
  static async putAgents(body) {
    return await ORBApi._doPut(`private/agents/${body.id}`, body);
  }

  static async deleteAgent(id) {
    return await ORBApi._doDelete(`/private/agents/${id}`);
  }

  // Function to be used to give some delay to methods in order to test loading scenarios
  // function sleep(ms) {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  //
}
